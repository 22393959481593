<template>
    <div>
        <div class="flex flex-wrap">
            <div class="p-2 m-4 border border-solid" style="min-width: 120px" v-for="(item, index) in itemList" :key="index">
                <div class="mb-4 text-center">
                    <span>{{ $common.getLangDB(item) }}</span>
                </div>
                <div class="flex justify-between space-x-4" :class="{ 'text-primary cursor-pointer hover:font-semibold': hasFile(item.item_code, mtype.mtype_code) }" v-for="(mtype, index2) in item.mtype" :key="index2" @click="openPopup(item, mtype)">
                    <span>{{ $common.getLangDB(mtype) }}</span>
                    <span>{{ getValue(item.item_code, mtype) }}</span>
                </div>
                <!-- 在欄位會有檔案，且未連結檔案時，顯示待上傳之檔案 -->
                <div v-if="showDrive(item)" class="text-primary cursor-pointer hover:font-semibold mt-4" :style="'display:' + (check_item_id_file(item)?'flex;':'none;')" @click="DriveFileCheckPopup(item)">
                    未上傳檔案查詢
                </div>
            </div>
        </div>
        <vs-popup :title="popupTitle" fullscreen :active.sync="popupActive" @close="closePopup">
            <diagnosisForm :source_url="sourceUrl" v-if="popupData">
                <template slot="form">
                    <!-- 單選 -->
                    <div class="mb-6 text-xl flex justify-center flex-wrap space-x-4 whitespace-no-wrap" v-if="selectedMtype.form_type == 'radio'">
                        <vs-radio v-for="(selection, index) in selectedMtype.value_map_his" v-model.number="form[selectedMtype.mtype_code]" :vs-name="selectedMtype.mtype_code" :vs-value="index">{{ selection }}</vs-radio>
                    </div>
                </template>
                <template slot="btns">
                    <vs-button class="cd-buttom-2w rounded" @click="upsertMeasureData">確定</vs-button>
                    <vs-button class="cd-buttom-2w rounded" type="border" @click="closePopup">取消</vs-button>
                </template>
                <template slot="file">
                    <div v-if="popupData.imagefile && getImgType(selectedMtype.mtype_code) == 'iframe'">
                        <iframe :src="getImgUrl(selectedMtype.mtype_code) + popupData.imagefile" frameborder="0" :height="imgHeight" :width="imgWidth + 'px'" v-if="popupData.imagefile"></iframe>
                    </div>
                    <div v-if="popupData.imagefile && getImgType(selectedMtype.mtype_code) == 'img'">
                        <img :src="getImgUrl(selectedMtype.mtype_code) + popupData.imagefile" :width="imgWidth + 'px'" />
                    </div>
                    <div class="mb-6" :style="'width: ' + imgWidth + 'px'">
                        <el-table fit class="w-full" :data="selectedMtype.value_table_map.rows" v-if="popupData.value_table && selectedMtype.value_table_map">
                            <el-table-column width="70" fixed="left" show-overflow-tooltip>
                                <template v-slot="scope">
                                    <span class="text-black font-semibold">{{ scope.row }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label-class-name="text-black text-left" v-for="(col, index2) in selectedMtype.value_table_map.cols" :key="index2" :label="col" width="60" show-overflow-tooltip>
                                <template v-slot="scope">
                                    <div class="text-black text-left">
                                        <span>{{ popupData.value_table[scope.$index][index2] }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="mt-6" v-if="selectedMtype.mtype_id == 36">
                            <canvas ref="lineChart"></canvas>
                        </div>
                        <div class="mt-6" v-if="selectedMtype.mtype_id == 36">
                            <img src="@/assets/images/pages/audiogram.jpg" :width="imgWidth + 'px'" />
                        </div>
                    </div>
                </template>
            </diagnosisForm>
        </vs-popup>
        <vs-popup :title="popupFileEdit" :active.sync="popupFileEditActive" @close="closePopup">
            <div id="title" class="" style="font-size: large;">
                <span>{{ patient.name }}的帳號(身分證字號) <span class="text-primary text-xl">{{patient.account}}</span></span>
            </div>
            <div v-if="Object.values(selfList).length > 0" class="mt-4">
                <!-- <vs-divider color="dark"></vs-divider> -->
                <div v-if="Object.values(selfList).length > 0" class="pl-3">
                    {{ patient.name }}的檔案<span class="text-success text-xl">正在上傳，預計2分鐘內上傳到醫生馬上看</span>
                </div>
                <div class="w-full flex m-3 my-2 items-center justify-between" v-for="self in selfList">
                    <div class="flex overflow-hidden">
                        <span class="whitespace-no-wrap text-success font-semibold">
                            {{ self.fileNamePart1 }}
                        </span>
                        <span class=" overflow-hidden truncate">
                            {{ self.fileNamePart2 }}
                        </span>
                    </div>
                    <div class=" mr-4">
                        <vs-button class="rounded-sm whitespace-no-wrap m-2 p-2 px-6" color="primary"  @click="openFile(self.webViewLink)">
                            檢視
                        </vs-button>
                    </div>
                </div>
            </div>
            <vs-divider color="dark" class="my-6"></vs-divider>
            <div class="">
                <div class="mx-3 mb-2">
                    <span class="">以下是<span style="color:red;">檔名輸入錯誤</span>的檔案</span>
                </div>
                <div class="grid grid-cols-1 gap-y-2" v-if="Object.values(fileList).length > 0">
                    <div v-for="fileitem in fileList" class="px-3 p-4 w-full" style="background-color: rgb(243 244 246);">
                        <div class="overflow-hidden whitespace-no-wrap">
                            <span class="font-semibold" style="color: red;">
                                {{ fileitem.fileNamePart1 }}
                            </span>
                            <span class="">
                                {{ fileitem.fileNamePart2 }}
                            </span>
                        </div>
                        <div class="flex items-center space-x-2 mt-2 ">
                            <div class="">
                                <vs-button class="rounded-sm whitespace-no-wrap p-2 px-6 text-sm" type="border" @click="openFile(fileitem.webViewLink)">
                                    檢視
                                </vs-button>
                            </div>
                            <div>
                                <vs-button class="rounded-sm whitespace-no-wrap p-2 px-6 text-sm" @click="selectDriveFile(fileitem)">
                                    更正為{{patient.account}}
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="m-4">
                    <span>無</span>
                </div>
                <div class="text-center mt-6 w-full">
                    <vs-button color="dark" type="border" class="w-full" @click="closePopup">關&nbsp;&nbsp;閉</vs-button>
                </div>
            </div>
        </vs-popup>
        <vs-popup :title="popupFileEditConfirm" :active.sync="popupFileEditConfirmActive" @close="closePopup">
            <div v-if="FileEditConfirmMode==0">
                <div class="text-center w-full my-4">
                    <div class="w-full my-2" :style="windowWidth<768?'max-width: 10rem;':'width:20rem;'">
                        <span class="whitespace-no-wrap font-semibold text-2xl " style="color: red;">
                            {{ fileConfirm.fileNamePart1}}
                        </span>
                        <span class="text-lg" :class="windowWidth<768?'overflow-hidden truncate':''">
                            {{ fileConfirm.fileNamePart2 }}
                        </span>
                    </div>
                    <div class="text-center">
                        更正為
                    </div>
                    <div class="w-full my-2" :style="windowWidth<768?'max-width: 10rem;':'width:20rem;'">
                        <span class="whitespace-no-wrap font-semibold text-2xl text-primary">
                            {{ patient.account}}
                        </span>
                        <span class="text-lg" :class="windowWidth<768?'overflow-hidden truncate':''">
                            {{ fileConfirm.fileNamePart2 }}
                        </span>
                    </div>
                    <div class="">
                        更正後，醫療影像會於兩分鐘內自動同步到醫生馬上看
                    </div>
                </div>
                <div class="text-center justify-between flex" :class="windowWidth>768?'m-4 mx-10 px-10':''">
                    <vs-button class="" color="primary" type="border" @click="updateDriveFileName(0)">取消</vs-button>
                    <vs-button @click="updateDriveFileName(1)">確定更正</vs-button>
                </div>
            </div>
            <div v-else>
                <div class="text-center">
                    <div class="text-3xl" style="color:green;">
                        <i class="el-icon-circle-check"></i>更正成功
                    </div>
                    <div class="w-full my-2 whitespace-no-wrap">
                        <span>
                            檔案已更正為
                        </span>
                        <span class="text-lg text-primary">
                            {{ patient.account}}
                        </span>
                        <span class="text-lg">
                            {{ fileConfirm.fileNamePart2 }}
                        </span>
                    </div>
                    <div class="mt-6 mb-2">
                        <vs-button @click="updateDriveFileName(2)" class="">關閉</vs-button>
                    </div>
                </div>
                
            </div>
        </vs-popup>
    </div>
</template>
<script>
import { getEventItemList, getEventMeasureData, upsertMeasureData, getDriveFileList, updateDriveFileName } from '@/api/user'
import diagnosisForm from '@/components/Module/diagnosisForm'
export default {
    components: {
        diagnosisForm,
    },
    data() {
        return {
            event: null,
            itemList: [],
            measureData: null,
            fileList: [],
            selfList: [],
            fileConfirm: [],
            popupActive: false,
            popupFileEditActive: false,
            popupFileEditConfirmActive: false,
            popupTitle: '',
            popupFileEdit: '',
            popupFileEditConfirm: '',
            popupData: null,
            sourceUrl: '',
            selectedItem: null,
            selectedMtype: null,
            form: [],
            chart: null,
            xAxisData: ['250(頻率)', '0.5K', '1K', '1.5K', '2K', '3K', '4K', '6K', '8K'],
            type_folder_id:99,
            item_id_with_File: [],
            FileEditConfirmMode: 0,

        }
    },
    props: {
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
        pid: {
            type: Number,
            required: true,
            default: 0,
        },
        patient: {
            type: Object,
            required: true,
            default: {},
        },
    },
    mounted() {
        this.getEventItemList()
        this.getEventMeasureData()
    },
    computed: {
        imgWidth() {
            const innerWidth = window.innerWidth
            const width = innerWidth > 630 ? innerWidth - 100 : innerWidth - 100
            return width
        },
        imgHeight() {
            const height = window.innerHeight
            return height
        },
        dname() {
            return this.$store.getters.name
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods: {
        // 取義檢活動量測項目
        getEventItemList() {
            const payload = {
                event_id: this.event_id,
                withEventItemMt: 1,
            }
            getEventItemList(payload).then((res) => {
                this.event = res.data.data.data[0]
                this.itemList = this.event.item_list
                this.item_id_with_File = res.data.data.item_id_with_File
            })
        },
        // 取義診活動所有會員的量測數據
        getEventMeasureData() {
            const payload = {
                event_id: this.event_id,
                pid: this.pid,
            }
            getEventMeasureData(payload).then((res) => {
                this.measureData = res.data.data.data[0]
            })
        },
        // 檢查item_id是否是有檔案呈現
        check_item_id_file(item){
            return (!this.hasFile(item.item_code, item.mtype.mtype_code)&&(_.find(this.item_id_with_File,{'item_id':item.item_id})))
        },
        // 取得量測值
        getValue(item_code, mtype) {
            const mtype_code = mtype.mtype_code
            if (!this.measureData) return '-'
            if (!this.measureData[item_code]) return '-'
            if (!this.measureData[item_code][mtype_code]) return '-'

            const value = parseInt(this.measureData[item_code][mtype_code].value)

            // 如果是多選
            if (mtype.form_type == 'checkbox') {
                const values = this.$common.decimalToArray(value)
                if (mtype.value_map) {
                    const map = mtype.value_map
                    let output = ''
                    _.forEach(values, function (value) {
                        if (map[value]) output += map[value] + ','
                    })
                    return _.trim(output, ',')
                }
            }

            // 醫師填寫的
            if (mtype.value_map_his) {
                const map = mtype.value_map_his
                if (map[value]) return map[value]
            }

            // 義工填寫的
            if (mtype.value_map) {
                const map = mtype.value_map
                if (map[value]) return map[value]
            }

            return this.measureData[item_code][mtype_code].value
        },
        openPopup(item, mtype) {
            const item_code = item.item_code
            const mtype_code = mtype.mtype_code
            if (!this.hasFile(item_code, mtype_code)) return

            this.selectedItem = item
            this.selectedMtype = mtype
            this.popupData = this.measureData[item_code][mtype_code]
            if (this.popupData.source_url) this.sourceUrl = this.popupData.source_url
            this.form[mtype.mtype_code] = parseInt(this.popupData.value)
            if (this.selectedMtype.mtype_id == 36) this.showChart()
            this.popupTitle = this.$common.getLangDB(mtype)
            this.popupActive = true
        },
        closePopup() {
            this.popupActive = false
            this.popupFileEditActive = false
            this.popupFileEditConfirmActive = false
            this.fileList=[]
            this.selfList=[]
            this.fileConfirm=[]
        },
        hasFile(item_code, mtype_code) {
            if (!this.measureData) return false
            if (!this.measureData[item_code]) return false
            if (!this.measureData[item_code][mtype_code]) return false

            if (this.measureData[item_code][mtype_code].imagefile) return true
            if (this.measureData[item_code][mtype_code].source_url) return true
            if (this.measureData[item_code][mtype_code].value_table) return true
            return false
        },
        getImgUrl(code) {
            const map = {
                ECG: 'electrocardiogram',
                Fundoscopy_OS: 'funduscopy',
                Fundoscopy_OD: 'funduscopy',
            }
            return map[code] ? process.env.VUE_APP_UPLOAD + '/' + map[code] + '/' : map[code]
        },
        getImgType(code) {
            const map = {
                ECG: 'iframe',
                Fundoscopy_OS: 'img',
                Fundoscopy_OD: 'img',
            }
            return map[code] ? map[code] : ''
        },
        // 寫入量測數據
        upsertMeasureData() {
            const _self = this
            let payload = { measures: [] }

            _.forEach(this.selectedItem.mtype, function (mtype) {
                if (_self.form[mtype.mtype_code] != null) {
                    payload.measures.push({
                        event_id: _self.event_id,
                        pid: _self.pid,
                        item_id: _self.selectedItem.item_id,
                        mtype_id: mtype.mtype_id,
                        value: Array.isArray(_self.form[mtype.mtype_code]) ? _self.$common.arrayToDecimal(_self.form[mtype.mtype_code]) : _self.form[mtype.mtype_code],
                        value_table: mtype.mtype_id == 36 ? _self.form['value_table'] : null, // 特規 純音聽力圖
                        writer: _self.dname,
                    })
                }
            })
            upsertMeasureData(payload).then((res) => {
                this.getEventMeasureData()
                this.closePopup()
            })
        },
        showChart() {
            const ctx = this.$refs.lineChart
            ctx.getContext('2d')
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.xAxisData,
                    padding: 1,
                    datasets: [
                        {
                            label: '左耳',
                            data: this.popupData.value_table[0],
                            borderColor: 'blue',
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: '右耳',
                            data: this.popupData.value_table[1],
                            borderColor: 'red',
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    reverse: true,
                                    beginAtZero: true,
                                    min: -20,
                                    max: 120,
                                    stepSize: 10,
                                },
                            },
                        ],
                    },
                    plugins: {
                        title: function (tooltipItem) {
                            title = '頻率：' + tooltipItem[0].label
                            return title
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || ''
                                if (label) {
                                    label = '分貝:' + tooltipItem.yLabel
                                }
                                return label
                            },
                        },
                    },
                },
            })
        },
        DriveFileCheckPopup(item){
            const _self = this
            _self.type_folder_id = _.find(_self.item_id_with_File,{'item_id':item.item_id}).type_folder_id
            const payload = {
                event_id: _self.event_id,
                type_folder_id: _self.type_folder_id,
                account: _self.patient.account,
                pid: _self.pid,
            }
            _self.$vs.loading()
            getDriveFileList(payload).then((res) => {
                _self.fileList = res.data.data.failFileList
                _self.selfList = res.data.data.selfUploading
                console.log(res)
            }).finally(()=>{
                _self.$vs.loading.close()
            })
            _self.popupFileEdit = ''
            _self.popupFileEditActive = true
        },
        openFile(link){
            console.log(link)
            window.open(link, '_blank')
        },
        selectDriveFile(file_item){
            this.closePopup()
            this.popupFileEditConfirm = '確認更正'
            this.fileConfirm=file_item
            this.FileEditConfirmMode=0
            this.popupFileEditConfirmActive = true
            console.log('selectDriveFile',file_item)
        },
        updateDriveFileName(index){
            if(index==0) {
                this.closePopup()
                this.getEventMeasureData()
                return 1;
            }

            else if(index==1){
                const _self = this
                const payload = {
                    type_folder_id: _self.type_folder_id,
                    file_id:        _self.fileConfirm.fileId,
                    file_name:      _self.patient.account+_self.fileConfirm.fileNamePart2,
                }
                
                _self.$vs.loading()
                updateDriveFileName(payload).then((res) => {
                    console.log(res)
                    this.FileEditConfirmMode=1
                    this.popupFileEditConfirm = '確認完成'
                }).finally(()=>{
                    this.getEventMeasureData()
                    _self.$vs.loading.close()
                })
                console.log('updateDriveFileName',payload)
            }
            else if(index==2){
                this.getEventMeasureData()
                this.popupFileEditConfirmActive=false
            }
            // _self.popupFileEditConfirmActive = false
        },
        showDrive(item){
            const itemCode = item.item_code
            const keysToCheck = ["imagefile", "source_url"];
            // check measureData have value and item_code in it
            if (!this.measureData) return false
            // console.log('step1 true',this.measureData,itemCode)
            //只取有檔案的item_id
            if(!_.find(this.item_id_with_File,{'item_id':item.item_id})) return false
            //檢查 measureData 如果沒有資料 說明肯定也沒有檔案 所以開啟drive雲端資料夾
            if (!this.measureData[itemCode]) {
                // console.log('step1 false',this.measureData,itemCode)
                return true;
            }
            // console.log('step2')
            // Step 2: 檢查measureData[item_code]中的每個在item.mtype的mtype_code是否都有包含keysToCheck的key
            const failedChecks = item.mtype.filter((mtype) => {
                const mtypeCode = mtype.mtype_code;

                // Check if this.measureData[item.item_code] has $mtypeCode
                if (this.measureData[itemCode][mtypeCode]) {
                const data = this.measureData[itemCode][mtypeCode];
                // Check if any of the keys exist
                return !keysToCheck.some((key) => data.hasOwnProperty(key));
                }

                return true; // If this.measureData[item.item_code][mtypeCode] doesn't exist
            });
            // console.log('step3')
            // Step 3: 只要有failedChecks，說明有項目沒有檔案 就開啟查drive雲端資料夾的功能
            return failedChecks.length >= 1;
        }
    },
}
</script>
