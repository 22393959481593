<template>
    <div v-if="queue && mr">
        <!-- CTA -->
        <vs-row class="cd-emr-select mb-base">
            <vs-col class="flex flex-wrap">
                <div class="mb-4 mr-4 flex items-center whitespace-no-wrap" v-if="checkCTAConfig('call') && queue.site == 2 && queue.closed == 0">
                    <div class="mr-2 flex items-center text-primary cursor-pointer hover:font-semibold" :style="queue.closed == 1 || queue.finish == 1 ? 'color: #666 !important;' : ''" @click.stop="call()">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                    <div class="text-sm" style="color: #666">
                        <span>通話對象：</span>
                        <span v-if="queue.cs_id != 0 && queue.proxy_call_did != did">{{ queue.callback_doctor.family_name + queue.callback_doctor.name }}</span>
                        <span v-else-if="queue.cs_id != 0 && queue.did != did">{{ queue.doctor_info.family_name + queue.doctor_info.name }}</span>
                        <span v-else-if="queue.cs_id == 0 && queue.proxy_call_did">{{ queue.callback_doctor.family_name + queue.callback_doctor.name }}</span>
                        <span v-else>{{ queue.patient_info.name }}</span>
                    </div>
                </div>
                <div class="mb-4 mr-4 flex flex-wrap items-center whitespace-no-wrap">
                    <div
                        class="mr-4 flex items-center cursor-pointer hover:font-semibold"
                        :class="checkInQueue.includes(queue.queue_id) || queue.closed == 1 || queue.finish == 1 ? 'text-grey' : 'text-success'"
                        @click.stop="showInvitePopup(queue.queue_id, queue.patient_info.mid)"
                        v-if="checkCTAConfig('invite') && queue.site == 2 && queue.closed == 0"
                    >
                        <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.invite') }}</span>
                    </div>
                    <div class="mr-4 flex items-center cursor-pointer hover:font-semibold text-grey" @click.stop="call(0, 0)" v-if="!checkInQueue.includes(queue.queue_id) && checkCTAConfig('enterRoom') && queue.site == 2 && queue.closed == 0">
                        <feather-icon class="mx-1" icon="LogInIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.enterRoom') }}</span>
                    </div>
                    <vs-button class="mr-4 cd-button-2w-icon" color="danger" icon-pack="feather" icon="icon-log-in" @click="call(0, 0)" v-if="checkInQueue.includes(queue.queue_id) && checkCTAConfig('enterRoom') && queue.site == 2 && queue.closed == 0">
                        {{ $t('apptMgmt.enterRoom') }}
                    </vs-button>
                    <div v-if="checkCTAConfig('enterRoom')">
                        <span style="color: #666" v-if="!queue.room && queue.closed == 0 && queue.finish == 0">發送邀請建立診間</span>
                        <span class="text-danger" v-else-if="checkInQueue.includes(queue.queue_id)">民眾已進入診間</span>
                        <span style="color: #666" v-else-if="!checkInQueue.includes(queue.queue_id) && queue.closed == 0 && queue.finish == 0">民眾尚未進入診間</span>
                    </div>
                </div>
                <div class="mb-4 mr-4 flex flex-wrap items-center whitespace-no-wrap" v-if="checkCTAConfig('finish')">
                    <!-- 完診按鈕 -->
                    <div v-if="queue.finish == 0 && !calling && queue.closed == 0 && doctortype != 2 && doctortype != 4">
                        <vs-button class="cd-button-2w" @click="updateFinishStatus()">{{ $t('emr.done') }}</vs-button>
                    </div>
                    <!-- 已完診 -->
                    <div class="whitespace-no-wrap" v-if="queue.finish == 1 && !calling && doctortype != 2 && doctortype != 4">
                        <vs-chip class="ml-1 mb-0" color="#666">{{ $t('emr.finish') }}</vs-chip>
                    </div>
                    <!-- 離開 -->
                    <div v-if="!calling && (doctortype == 2 || doctortype == 4)">
                        <vs-button class="cd-button-2w" @click="exit">{{ $t('emr.exit') }}</vs-button>
                    </div>
                </div>
            </vs-col>
        </vs-row>
        <vs-row class="cd-emr-select mb-base" v-if="false">
            <vs-col vs-type="flex" vs-justify="flex-end">
                <!-- 通話 -->
                <vs-button class="cd-button-2w flex items-center cursor-pointer hover:font-semibold" :color="queue.closed == 1 || queue.finish == 1 || calling ? 'grey ' : 'primary'" @click.stop="call()">
                    {{ $t('apptMgmt.call') }}
                </vs-button>
                <!-- 邀請 -->
                <vs-button class="cd-button-2w flex items-center cursor-pointer hover:font-semibold" :color="checkInQueue.includes(parseInt(queue_id)) || queue.closed == 1 || queue.finish == 1 ? 'grey' : 'success'" @click.stop="showInvitePopup(queue.queue_id, queue.patient_info.mid)">
                    {{ $t('apptMgmt.invite') }}
                </vs-button>
                <!-- 看診 -->
                <vs-button class="cd-button-2w flex items-center cursor-pointer hover:font-semibold" :color="checkInQueue.includes(parseInt(queue_id)) && !calling ? 'danger' : 'grey'" @click.stop="call(0, 0)">
                    {{ $t('apptMgmt.enterRoom') }}
                </vs-button>
                <!-- 完診按鈕 -->
                <div v-if="queue.finish == 0 && !calling && queue.closed == 0 && doctortype != 2 && doctortype != 4">
                    <vs-button class="cd-button-2w" @click="updateFinishStatus()">{{ $t('emr.done') }}</vs-button>
                </div>
                <!-- 已完診 -->
                <div class="whitespace-no-wrap" v-if="queue.finish == 1 && !calling && doctortype != 2 && doctortype != 4">
                    <vs-chip class="ml-1 mb-0" color="#666">{{ $t('emr.finish') }}</vs-chip>
                </div>
                <!-- 離開 -->
                <div v-if="!calling && (doctortype == 2 || doctortype == 4)">
                    <vs-button class="cd-button-2w" @click="exit">{{ $t('emr.exit') }}</vs-button>
                </div>
            </vs-col>
        </vs-row>
        <!-- 掛號資訊 -->
        <vs-row class="cd-emr-select mb-base">
            <!-- 看診日期＆看診時段＆服務項目 -->
            <vs-col class="sm:px-5 px-0" vs-w="6" vs-xs="12">
                <div>
                    <span class="cd-text-muted">{{ $t('emr.appt.date') }}：</span>
                    <span>{{ queue.date }}</span>
                </div>
                <div>
                    <span class="cd-text-muted">{{ $t('emr.appt.interval') }}：</span>
                    <span>{{ $common.getInterval(queue.interval) }}</span>
                </div>
                <div>
                    <span class="cd-text-muted">{{ $t('emr.appt.service') }}：</span>
                    <span>{{ $common.getPMemo(queue.p_memo) }}</span>
                </div>
            </vs-col>
            <!-- 個人病史＆過敏事項＆病人備註 -->
            <vs-col class="sm:px-5 px-0" vs-w="6" vs-xs="12">
                <div>
                    <span class="cd-text-muted">{{ $t('emr.appt.personalSickHistory') }}：</span>
                    <span>{{ userEMRPersonSickData ? userEMRPersonSickData : '無' }}</span>
                </div>
                <div>
                    <span class="cd-text-muted">{{ $t('emr.appt.allergy') }}：</span>
                    <span>{{ userEMRSensitiveMattersData ? userEMRSensitiveMattersData : '無' }}</span>
                </div>
                <div v-if="apptConfig.patientNotes.show">
                    <span class="cd-text-muted">{{ $common.getLang(apptConfig.patientNotes.i18n) }}：</span>
                    <span>{{ notes }}</span>
                    <span class="ml-2 text-primary cd-p-pointer hover:font-bold" @click="patientNotesPopup = true">
                        <feather-icon icon="Edit2Icon" svgClasses="h-4 w-4"></feather-icon>
                    </span>
                </div>
            </vs-col>
        </vs-row>
        <!-- 義診檢查數據 -->
        <vs-row class="cd-emr-select mb-base" v-if="apptConfig.checkInfo.show && doctortype != 2 && doctortype != 4 && queue.event_patient_queue.length > 0">
            <FreeClinic :event_id="queue.event_patient_queue[0].event_patient.event_id" :pid="queue.pid" :patient="patient"></FreeClinic>
        </vs-row>
        <!-- SOAP -->
        <vs-row class="cd-emr-select">
            <!-- S -->
            <vs-col class="sm:px-5 px-0 mb-base" vs-w="6" vs-xs="12" v-if="apptConfig.S.show && doctortype != 2 && doctortype != 4">
                <div class="mb-2 flex justify-between">
                    <div class="flex items-center">
                        <!-- title -->
                        <p class="font-bold">
                            {{ $common.getLang(apptConfig.S.i18n) }}
                        </p>
                        <!-- icon -->
                        <vs-dropdown vs-custom-content vs-trigger-click class="ml-1 mt-1 cd-text-muted cursor-pointer" v-if="editHistory.s && editHistory.s.length != 0">
                            <feather-icon icon="ClockIcon" svgClasses="w-4 h-4" />
                            <vs-dropdown-menu style="width: 200px">
                                <VuePerfectScrollbar style="height: 30vh" :settings="settings">
                                    <div class="p-2" v-for="(item, index) in editHistory.s" :key="index">
                                        <p class="cd-date">
                                            {{ item.created_at }}
                                        </p>
                                        <p class="text-right">
                                            {{ item.data }}
                                        </p>
                                        <vs-divider class="my-0"></vs-divider>
                                    </div>
                                </VuePerfectScrollbar>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <!-- user feeback -->
                        <feather-icon icon="RefreshCwIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="memoSFeeback == true" />
                        <p class="cd-text-muted" v-if="memoSFeeback == true">
                            {{ $t('emr.appt.saving') }}
                        </p>
                        <feather-icon icon="CheckIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="memoSFeeback == false" />
                        <p class="cd-text-muted" v-if="memoSFeeback == false">
                            {{ $t('emr.appt.saved') }}
                        </p>
                    </div>
                    <importSetWord :dataType="'s/all'"></importSetWord>
                </div>
                <textarea class="w-full cd-emr-textarea" rows="6" v-model="memo_s"></textarea>
            </vs-col>
            <!-- O -->
            <vs-col class="sm:px-5 px-0 mb-base" vs-w="6" vs-xs="12" v-if="apptConfig.O.show && doctortype != 2 && doctortype != 4">
                <div class="mb-2 flex justify-between">
                    <div class="flex items-center">
                        <!-- title -->
                        <p class="font-bold">
                            {{ $common.getLang(apptConfig.O.i18n) }}
                        </p>
                        <!-- icon -->
                        <vs-dropdown vs-custom-content vs-trigger-click class="ml-1 mt-1 cd-text-muted cursor-pointer" v-if="editHistory.o && editHistory.o.length != 0">
                            <feather-icon icon="ClockIcon" svgClasses="w-4 h-4" />
                            <vs-dropdown-menu style="width: 200px">
                                <VuePerfectScrollbar style="height: 30vh" :settings="settings">
                                    <div class="p-2" v-for="(item, index) in editHistory.o" :key="index">
                                        <p class="cd-date">
                                            {{ item.created_at }}
                                        </p>
                                        <p class="text-right">
                                            {{ item.data }}
                                        </p>
                                        <vs-divider class="my-0"></vs-divider>
                                    </div>
                                </VuePerfectScrollbar>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <!-- user feeback -->
                        <feather-icon icon="RefreshCwIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="memoOFeeback == true" />
                        <p class="cd-text-muted" v-if="memoOFeeback == true">
                            {{ $t('emr.appt.saving') }}
                        </p>
                        <feather-icon icon="CheckIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="memoOFeeback == false" />
                        <p class="cd-text-muted" v-if="memoOFeeback == false">
                            {{ $t('emr.appt.saved') }}
                        </p>
                    </div>
                    <importSetWord :dataType="'o/all'"></importSetWord>
                </div>
                <textarea class="w-full cd-emr-textarea" rows="6" v-model="memo_o"></textarea>
            </vs-col>
            <!-- A -->
            <vs-col class="sm:px-5 px-0 mb-base" vs-w="6" vs-xs="12" v-if="apptConfig.A.show && doctortype != 2 && doctortype != 4">
                <div class="mb-2 flex items-center">
                    <p class="mr-4 font-bold">
                        {{ $common.getLang(apptConfig.A.i18n) }}
                    </p>
                    <feather-icon icon="RefreshCwIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="ICDFeeback == true" />
                    <p class="cd-text-muted" v-if="ICDFeeback == true">
                        {{ $t('emr.appt.saving') }}
                    </p>
                    <feather-icon icon="CheckIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="ICDFeeback == false" />
                    <p class="cd-text-muted" v-if="ICDFeeback == false">
                        {{ $t('emr.appt.saved') }}
                    </p>
                </div>
                <v-select
                    class="w-56 cd-select bg-white"
                    ref="icd"
                    multiple
                    autocomplete="on"
                    v-model="ICD"
                    :options="optionICD"
                    :components="{ OpenIndicator }"
                    :dropdown-should-open="ICD10DropdownShouldOpenFunc"
                    :placeholder="$t('emr.appt.enterMedicalPlan')"
                    @search="get_icd10"
                    @option:selected="insertICD"
                >
                </v-select>
                <div style="height: 90px">
                    <ag-grid-vue style="height: 100%" class="ag-theme-balham" :columnDefs="columnICD" :rowData="resultICD" :singleClickEdit="true" :stopEditingWhenCellsLoseFocus="true" @cellValueChanged="updateICD($event)" @cellClicked="deleteICD($event)"> </ag-grid-vue>
                </div>
            </vs-col>
            <!-- 建議紀錄 -->
            <vs-col class="sm:px-5 px-0 mb-base" vs-w="6" vs-xs="12" v-if="apptConfig.suggestion.show">
                <div class="mb-2 flex justify-between">
                    <div class="flex items-center">
                        <!-- title -->
                        <p class="font-bold">
                            {{ $common.getLang(apptConfig.suggestion.i18n) }}
                        </p>
                        <!-- icon -->
                        <vs-dropdown vs-custom-content vs-trigger-click class="ml-1 mt-1 cd-text-muted cursor-pointer" v-if="editHistory.suggestion && editHistory.suggestion.length != 0">
                            <feather-icon icon="ClockIcon" svgClasses="w-4 h-4" />
                            <vs-dropdown-menu style="width: 200px">
                                <VuePerfectScrollbar style="height: 30vh" :settings="settings">
                                    <div class="p-2" v-for="(item, index) in editHistory.suggestion" :key="index">
                                        <p class="cd-date">
                                            {{ item.created_at }}
                                        </p>
                                        <p class="text-right">
                                            {{ item.data }}
                                        </p>
                                        <vs-divider class="my-0"></vs-divider>
                                    </div>
                                </VuePerfectScrollbar>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <!-- user feeback -->
                        <feather-icon icon="RefreshCwIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="suggestionFeeback == true" />
                        <p class="cd-text-muted" v-if="suggestionFeeback == true">
                            {{ $t('emr.appt.saving') }}
                        </p>
                        <feather-icon icon="CheckIcon" class="ml-4 mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="suggestionFeeback == false" />
                        <p class="cd-text-muted" v-if="suggestionFeeback == false">
                            {{ $t('emr.appt.saved') }}
                        </p>
                    </div>
                    <importSetWord :dataType="'memop/all'"></importSetWord>
                </div>
                <textarea class="w-full cd-emr-textarea" rows="6" v-model="memo_p"></textarea>
            </vs-col>
            <!-- P 用藥 -->
            <vs-col class="sm:px-5 px-0 mb-base" v-if="apptConfig.P.show && doctortype != 2 && doctortype != 4">
                <vs-row class="mb-2">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" vs-xs="12">
                        <p class="mr-4 font-bold">
                            {{ $common.getLang(apptConfig.P.i18n) }}
                        </p>
                        <vs-checkbox class="mr-4" v-model="continuousPrescription">{{ $t('emr.appt.continuousPrescription') }}</vs-checkbox>
                        <feather-icon icon="RefreshCwIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="medicineFeeback == true" />
                        <p class="cd-text-muted" v-if="medicineFeeback == true">
                            {{ $t('emr.appt.saving') }}
                        </p>
                        <feather-icon icon="CheckIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="medicineFeeback == false" />
                        <p class="cd-text-muted" v-if="medicineFeeback == false">
                            {{ $t('emr.appt.saved') }}
                        </p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6" vs-xs="12">
                        <v-select
                            class="mr-4 w-48 cd-select bg-white"
                            multiple
                            autocomplete="on"
                            v-model="medicinePackageCode"
                            :options="optionMedicinePackage"
                            :components="{ OpenIndicator }"
                            :dropdown-should-open="medicinePackageDropdownShouldOpenFunc"
                            :placeholder="$t('emr.appt.importMedicalPack')"
                            @search="get_medicine_package_info"
                            @option:selected="importMedicine"
                        >
                        </v-select>
                        <p class="cd-p-pointer text-primary" @click="openSaveMedicinePackagePopup">
                            {{ $t('popup.save') }}
                        </p>
                    </vs-col>
                </vs-row>
                <v-select
                    class="w-56 cd-select bg-white"
                    ref="medicine"
                    multiple
                    autocomplete="on"
                    v-model="medicine"
                    :options="optionMedicine"
                    :components="{ OpenIndicator }"
                    :dropdown-should-open="medicineDropdownShouldOpenFunc"
                    :placeholder="$t('emr.appt.enterMedicalCode')"
                    @search="get_medicine_info"
                    @option:selected="insertMedicine"
                >
                </v-select>
                <div style="height: 250px">
                    <ag-grid-vue style="height: 100%" class="ag-theme-balham" :columnDefs="columnMedicine" :rowData="resultMedicine" :singleClickEdit="true" :stopEditingWhenCellsLoseFocus="true" @cellValueChanged="updateMedicine($event)" @cellClicked="deleteMedicine($event)"> </ag-grid-vue>
                </div>
            </vs-col>
            <!-- 推薦商品 -->
            <vs-col class="sm:px-5 px-0 mb-base" vs-w="12" vs-xs="12" v-if="allow_shop">
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10" vs-xs="12">
                        <div class="flex items-center">
                            <p class="mr-8 font-bold">
                                {{ $t('emr.appt.Recommend') }}
                            </p>
                            <p class="cd-p-pointer text-primary" @click="openUseProductPopup()" v-if="recomm.length == 0">選擇常用商品</p>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2" vs-xs="12">
                        <div class="mb-2 flex items-center">
                            <p class="cd-p-pointer text-primary" @click="openInsertRecommPopup()" v-if="recomm.length == 0">
                                {{ $t('emr.appt.SendRecommend') }}
                            </p>
                        </div>
                    </vs-col>
                </vs-row>
                <div v-if="recomm.length == 0">
                    <v-select class="w-56 cd-select bg-white" ref="icd" multiple autocomplete="on" v-model="PRODUCT" :options="optionProduct" :components="{ OpenIndicator }" :placeholder="$t('emr.appt.SearchRecommend')" @search="getCartProduct" @option:selected="insertProduct"> </v-select>
                </div>
                <div style="height: 250px">
                    <ag-grid-vue style="height: 100%" class="ag-theme-balham" :columnDefs="columnRecomm" :rowData="reportrecomm" :singleClickEdit="true" :stopEditingWhenCellsLoseFocus="true" @cellClicked="handleRecommCellClick($event)"> </ag-grid-vue>
                </div>
            </vs-col>
        </vs-row>
        <!-- 病人備註 -->
        <vs-popup :title="$t('emr.appt.patientNotes')" :active.sync="patientNotesPopup">
            <div>
                <div class="flex items-center">
                    <feather-icon icon="RefreshCwIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="patientNotesFeeback == true" />
                    <p class="cd-text-muted" v-if="patientNotesFeeback == true">
                        {{ $t('emr.appt.saving') }}
                    </p>
                    <feather-icon icon="CheckIcon" class="mr-1 cd-text-muted" svgClasses="w-4 h-4" v-if="patientNotesFeeback == false" />
                    <p class="cd-text-muted" v-if="patientNotesFeeback == false">
                        {{ $t('emr.appt.saved') }}
                    </p>
                </div>
                <textarea class="w-full cd-emr-textarea" rows="6" v-model="notes"></textarea>
            </div>
        </vs-popup>
        <!-- 儲存處方集視窗 -->
        <vs-popup fullscreen :title="$t('emr.appt.saveRx')" :active.sync="saveMedicinePackagePopup">
            <vs-row>
                <vs-col vs-w="6">
                    <div class="cd-form-group flex items-center">
                        <span class="mr-4">* {{ $t('emr.appt.rxName') }}</span>
                        <vs-input v-model="packageName" :danger="packageNameErrorBool" :danger-text="packageNameErrorMsg" />
                    </div>
                    <div class="cd-form-group flex items-center">
                        <span class="mr-4">* {{ $t('emr.appt.rxCode') }}</span>
                        <vs-input v-model="packageCode" :danger="packageCodeErrorBool" :danger-text="packageCodeErrorMsg" />
                    </div>
                </vs-col>
                <vs-col class="cd-form-group text-right" vs-w="6">
                    <vs-button class="cd-button-2w" @click="saveMedicinePackage" :disabled="!packageName || !packageCode || packageNameErrorBool || packageCodeErrorBool || resultMedicinePackage.length == 0">
                        {{ $t('popup.save') }}
                    </vs-button>
                </vs-col>
                <vs-divider class="cd-form-group" />
                <vs-col class="cd-form-group">
                    <span>{{ $t('emr.appt.rxContent') }}</span>
                </vs-col>
                <vs-col class="cd-emr-select">
                    <v-select
                        class="w-56 cd-select bg-white"
                        ref="medicine"
                        multiple
                        autocomplete="on"
                        v-model="medicine"
                        :options="optionMedicine"
                        :components="{ OpenIndicator }"
                        :dropdown-should-open="medicineDropdownShouldOpenFunc"
                        :placeholder="$t('emr.appt.enterMedicalCode')"
                        @search="get_medicine_info"
                        @option:selected="insertMedicinePackage"
                    >
                    </v-select>
                </vs-col>
                <vs-col>
                    <ag-grid-vue id="medicinePackageGrid" class="ag-theme-balham" :columnDefs="columnMedicine" :rowData="resultMedicinePackage" :singleClickEdit="true" :stopEditingWhenCellsLoseFocus="true" @grid-ready="onGridReadyMedicinePackage" @cellClicked="deleteMedicinePackage($event)">
                    </ag-grid-vue>
                </vs-col>
            </vs-row>
        </vs-popup>
        <!-- 選擇常用商品 -->
        <vs-popup :active.sync="useProductPopup" title="選擇常用商品">
            <vs-row>
                <vs-col v-for="(item, index) in doctorProducts" :key="index">
                    <div class="flex justify-between">
                        <vs-checkbox v-model="checkUseProduct" :vs-value="item">{{ item.name }}</vs-checkbox>
                        <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="deleteDoctorProducts(item.product_id)"></feather-icon>
                    </div>
                </vs-col>
                <div class="cd-form-group text-center" v-if="!doctorProducts[0]">
                    <span>{{ '請先新增常用商品' }}</span>
                </div>
            </vs-row>
            <div class="flex justify-center">
                <vs-button class="cd-button-2w" @click="useProduct" :disabled="!doctorProducts[0]">{{ '選擇' }}</vs-button>
                <vs-button class="cd-button-2w ml-8" type="border" @click="closeUseProductPopup">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
        <!-- 推薦商品圖片視窗 -->
        <vs-popup :active.sync="cartImagePopupActive" :title="$t('emr.appt.RecommendProduct')">
            <span class="font-bold">{{ cartProductName + cartProductPrice + '元' }}</span>
            <iframe width="100%" height="500vh" :src="cartProductImage" frameborder="0" allowfullscreen></iframe>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center">
                <vs-button class="cd-button-4w" type="border" @click="goToCart">{{ $t('emr.appt.goProductShop') }}</vs-button>
                <vs-button class="cd-button-2w mr-4" type="border" style="border-color: black; color: black" @click="closeCartpopup">{{ $t('popup.cancel') }}</vs-button>
            </vs-col>
        </vs-popup>
        <!-- 送出推薦商品視窗 -->
        <vs-popup :active.sync="insertRecommendPopup" @close="closeRecommPopup" :title="$t('emr.appt.AddRecommend')">
            <div class="cd-form-group text-center">{{ $t('emr.appt.CheckSend') }}<br /></div>
            <div class="flex justify-center">
                <vs-button class="cd-button-2w" @click="insertRecomm()">{{ $t('popup.save') }}</vs-button>
                <vs-button class="cd-button-2w ml-8" type="border" @click="closeRecommPopup">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import {
    getQueues,
    fetchPatientNotes,
    upsertPatientNotes,
    fetchMRHistory,
    updateMRNoteData,
    updateSubjective,
    updateObject,
    get_icd10,
    get_medicine_info,
    upsertICD,
    deleteICD,
    upsertMedicine,
    deleteMedicine,
    fetchMedicinePackage,
    saveMedicinePackage,
    fetchMedFreqRoute,
    updateContinuousPrescription,
    fetchPharmacyList,
    searchcartproduct,
    setProduct,
    getDoctorProducts,
    deleteProductFromDB,
    insertRecommend,
    getRecommend,
    updateFinishStatus,
} from '@/api/user'
import _ from 'lodash'
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { AgGridVue } from 'ag-grid-vue'
import numericEditor from '@/components/ag-grid/numericEditor'
import floatEditor from '@/components/ag-grid/floatEditor'
import importSetWord from '@/components/importSetWord'
import FreeClinic from '@/components/Emr/FreeClinic'
export default {
    components: {
        vSelect,
        VuePerfectScrollbar,
        importSetWord,
        AgGridVue,
        FreeClinic,
    },
    data() {
        return {
            queue: null,
            mr: null,
            patientNotesPopup: false,
            useProductPopup: false,
            cartImagePopupActive: false,
            insertRecommendPopup: false,
            patientNotesFeeback: null,
            memoSFeeback: null,
            memoOFeeback: null,
            ICDFeeback: null,
            medicineFeeback: null,
            suggestionFeeback: null,
            notes: '',
            editHistory: [],
            ICD: [],
            resultICD: [],
            optionICD: [],
            medicine: [],
            resultMedicine: [],
            optionMedicine: [],
            continuousPrescription: false,
            // 去掉下拉式選單箭頭
            OpenIndicator: {
                render: (createElement) => createElement('span', { class: { toggle: true } }),
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            ICD10DropdownShouldOpen: false,
            medicineDropdownShouldOpen: false,
            medicinePackageDropdownShouldOpen: false,
            saveMedicinePackagePopup: false,
            medicinePackageCode: [],
            optionMedicinePackage: [],
            resultMedicinePackage: [],
            packageName: '',
            packageCode: '',
            gridApiMedicinePackage: null,
            packageNameErrorMsg: '',
            packageCodeErrorMsg: '',
            packageNameErrorBool: false,
            packageCodeErrorBool: false,
            cartProductName: '',
            cartProductPrice: 0,
            cartProductUrl: '',
            cartImagePopupActive: false,
            cartProductImage: '',
            recomm: [],
            reportrecomm: [],
            optionProduct: [],
            PRODUCT: [],
            doctorProducts: [],
            checkUseProduct: [],
            patient: {
                name: '',
                account: '',
            },
        }
    },
    mounted() {
        this.getQueues() // 取掛號主頁資料
        this.fetchMedicinePackage() // 取處方集
        this.fetchMedFreqRoute() // 取藥品的用法＆途徑
        this.fetchPharmacyList() // 取合作藥局清單
        this.getRecommend()
        this.getDoctorProducts()
        this.createBus()
    },
    watch: {
        packageName(v) {
            if (
                _.findIndex(this.medicinePackage, function (o) {
                    return o.package_name == v
                }) != -1
            ) {
                this.packageNameErrorMsg = this.$t('emr.appt.duplicateName')
                this.packageNameErrorBool = true
            } else {
                this.packageNameErrorBool = false
            }
        },
        packageCode(v) {
            if (
                _.findIndex(this.medicinePackage, function (o) {
                    return o.package_code == v
                }) != -1
            ) {
                this.packageCodeErrorMsg = this.$t('emr.appt.duplicateCode')
                this.packageCodeErrorBool = true
            } else {
                this.packageCodeErrorBool = false
            }
        },
        notes() {
            this.upsertPatientNotes()
        },
        memo_s() {
            this.updateSubjective()
        },
        memo_o() {
            this.updateObject()
        },
        memo_p() {
            this.updateMrNote()
        },
        continuousPrescription(v) {
            this.updateContinuousPrescription()
        },
        patientNotesFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.patientNotesFeeback = null
                }, 5000)
            }
        },
        memoSFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.memoSFeeback = null
                }, 5000)
            }
        },
        memoOFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.memoOFeeback = null
                }, 5000)
            }
        },
        ICDFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.ICDFeeback = null
                }, 5000)
            }
        },
        medicineFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.medicineFeeback = null
                }, 5000)
            }
        },
        suggestionFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.suggestionFeeback = null
                }, 5000)
            }
        },
    },
    computed: {
        ...mapState(['doctortype', 'did', 'checkInQueue']),
        queue_id() {
            return this.$route.params.qid ? this.$route.params.qid : 0
        },
        partner_id() {
            return this.$store.state.partner
        },
        allow_shop() {
            return this.$store.state.allow_shop
        },
        apptConfig() {
            return JSON.parse(localStorage.getItem('his_config')).appt
        },
        remoteApptConfig() {
            return JSON.parse(localStorage.getItem('his_config')).remoteAppt
        },
        // 個人病史
        userEMRPersonSickData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRPersonSickDataE
            } else {
                return this.$store.getters.userEMRPersonSickData
            }
        },
        // 過敏事項
        userEMRSensitiveMattersData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRSensitiveMattersDataE
            } else {
                return this.$store.getters.userEMRSensitiveMattersData
            }
        },
        // 建議紀錄
        memo_p: {
            get() {
                return this.$store.getters.memoP
            },
            set(val) {
                this.$store.commit('SET_MEMO_P', val)
            },
        },
        // 病患主觀自覺徵候
        memo_s: {
            get() {
                return this.$store.getters.memoS
            },
            set(val) {
                this.$store.commit('SET_MEMO_S', val)
            },
        },
        // 醫療人員客觀診斷評估
        memo_o: {
            get() {
                return this.$store.getters.memoO
            },
            set(val) {
                this.$store.commit('SET_MEMO_O', val)
            },
        },
        columnICD() {
            var nameWidth = (this.windowWidth * 5) / 12 - 270 - 75
            nameWidth = nameWidth < 150 ? 150 : nameWidth
            let rs = [
                {
                    headerName: this.$t('emr.appt.code_ICD'),
                    field: 'code',
                    width: 100,
                },
                {
                    headerName: this.$t('emr.appt.name_ICD'),
                    field: 'label',
                    width: nameWidth,
                },
                {
                    headerName: this.$t('emr.appt.remarks_ICD'),
                    field: 'notes',
                    editable: true,
                    width: 120,
                    cellStyle: { color: '#003f8e' },
                },
                {
                    headerName: '',
                    field: 'del',
                    width: 50,
                    cellRenderer: function () {
                        return '<span><i class="material-icons cursor-pointer">clear</i></span>'
                    },
                },
            ]
            return rs
        },
        columnRecomm() {
            var nameWidth = (this.windowWidth * 10) / 12 - 50 - 150 - 100 - 100 - 100 - 50 - 100 - 105
            nameWidth = nameWidth < 150 ? 150 : nameWidth

            return [
                {
                    headerName: '常用',
                    field: 'star',
                    width: 50,
                    cellRenderer: (params) => (this.checkIsStar(params.data.product_id) ? '<i class="material-icons cursor-pointer text-success">star</i>' : '<i class="material-icons cursor-pointer">star_border</i>'),
                },
                {
                    headerName: this.$t('emr.appt.ProductName'),
                    field: 'name',
                    width: 150,
                },
                {
                    headerName: this.$t('emr.appt.ProductNumber'),
                    field: 'quantity',
                    width: 100,
                    editable: true,
                    cellStyle: { color: '#003f8e' },
                },
                {
                    headerName: this.$t('emr.appt.Price'),
                    field: 'price',
                    width: 100,
                },
                {
                    headerName: '',
                    field: 'image',
                    width: 100,
                    cellRenderer: function () {
                        return '<span><i class="cursor-pointer" style="color:#003f8e;">檢視圖片</i></span>'
                    },
                },
                {
                    headerName: '',
                    field: 'del',
                    width: 50,
                    cellRenderer: function () {
                        return '<span><i class="material-icons cursor-pointer">clear</i></span>'
                    },
                },
                {
                    headerName: this.$t('emr.appt.Manufacturer'),
                    field: 'manufacturer',
                    width: 100,
                },
                {
                    headerName: this.$t('emr.appt.Description'),
                    field: 'description',
                    width: nameWidth,
                },
            ]
        },
        columnMedicine() {
            var nameWidth = this.saveMedicinePackagePopup ? this.windowWidth - 100 - 80 - 80 - 80 - 80 - 80 - 80 - 80 - 120 - 50 - 69 : (this.windowWidth * 10) / 12 - 100 - 80 - 80 - 80 - 80 - 80 - 80 - 80 - 120 - 50 - 105
            nameWidth = nameWidth < 150 ? 150 : nameWidth
            return [
                {
                    headerName: this.$t('emr.appt.code_p'),
                    field: 'med_info_id',
                    width: 100,
                },
                {
                    headerName: this.$t('emr.appt.name_p'),
                    field: 'medical_en_name',
                    width: nameWidth,
                },
                {
                    headerName: this.$t('emr.appt.unit_p'),
                    field: 'unit',
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.dose_p'),
                    field: 'dose',
                    cellEditor: floatEditor,
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.frequency_p'),
                    field: 'med_freq_id',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: this.freqArr,
                    },
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.route_p'),
                    field: 'med_route_id',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: this.routeArr,
                    },
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.days_p'),
                    field: 'days',
                    cellEditor: numericEditor,
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.total_p'),
                    field: 'total_dose',
                    cellEditor: floatEditor,
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.daily_p'),
                    field: 'daily_dose',
                    cellEditor: floatEditor,
                    editable: true,
                    width: 80,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: this.$t('emr.appt.notes_p'),
                    field: 'notes',
                    editable: true,
                    width: 120,
                    cellStyle: {
                        color: '#003f8e',
                    },
                },
                {
                    headerName: '',
                    field: 'del',
                    width: 50,
                    cellRenderer: function () {
                        return '<span><i class="material-icons cursor-pointer">clear</i></span>'
                    },
                },
            ]
        },
        // 是否在通話中
        calling() {
            if (this.$store.getters.videoPath) {
                this.$store.commit('SET_CALLING', true)
            }
            return this.$store.getters.calling
        },
    },
    methods: {
        // 取掛號主頁資料
        getQueues() {
            const payload = {
                queue_id: this.queue_id,
                withCall: 1,
                withEnterprise: 1,
                withFreeClinic: 1,
                withMedicalRecord: 1,
            }
            getQueues(payload)
                .then((res) => {
                    this.queue = res.data.data.data[0]
                    this.patient.name = this.queue.patient_info.name
                    this.patient.account = this.queue.patient_info.member.account
                    this.$store.commit('SET_ROOM_ID', this.queue.room_id ? this.queue.room_id : 0)

                    // mr相關
                    this.mr = this.queue.medical_record.filter((item) => {
                        return item.did == this.did
                    })
                    this.mr = this.mr[0]
                    if (!this.mr) return
                    // 病患主觀自覺徵候
                    this.$store.commit('SET_MEMO_S', this.mr.memo_s ? this.mr.memo_s : '')
                    // 醫療人員客觀診斷評估
                    this.$store.commit('SET_MEMO_O', this.mr.memo_o ? this.mr.memo_o : '')
                    // 建議紀錄
                    this.$store.commit('SET_MEMO_P', this.mr.memo_p ? this.mr.memo_p : '')
                    // ICD
                    if (this.mr.json_a) {
                        this.mr.json_a.forEach((v) => {
                            this.resultICD.push({
                                label: v.ename,
                                code: v.disease_code,
                                notes: v.notes,
                            })
                        })
                    }
                    // 連續處方籤
                    this.continuousPrescription = this.mr.rx_type == 2 ? true : false
                    // 藥囑
                    if (this.mr.json_p) {
                        this.mr.json_p.forEach((v) => {
                            this.resultMedicine.push({
                                id: v.id,
                                med_info_id: v.med_info_id,
                                medical_en_name: v.medical_en_name,
                                unit: v.unit,
                                dose: v.dose,
                                med_freq_id: v.med_freq_id,
                                med_route_id: v.med_route_id,
                                days: v.days,
                                total_dose: v.total_dose,
                                daily_dose: v.daily_dose,
                                notes: v.notes,
                            })
                        })
                    }
                })
                .then(() => {
                    this.fetchMRHistory() // 取完診後的醫囑修改紀錄
                })
        },
        // 取病人備註
        fetchPatientNotes() {
            let payload = { partner_id: this.partner_id, pid: this.queue.pid }
            fetchPatientNotes(payload).then((res) => {
                if (res.data.status == 'OK' && res.data.data.length > 0) {
                    this.notes = res.data.data[0].emr_note
                }
            })
        },
        // 新增＆修改病人備註
        upsertPatientNotes() {
            this.patientNotesFeeback = true
            let payload = {
                partner_id: this.partner_id,
                spid: this.queue.pid,
                notes: this.notes,
            }
            upsertPatientNotes(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.patientNotesFeeback = false
                }
            })
        },
        // 取完診後的醫囑修改紀錄
        fetchMRHistory() {
            fetchMRHistory(this.mr.mr_id).then((res) => {
                if (res.data.status === 'OK') {
                    if (res.data.msg) {
                        console.log(res.data.msg)
                    } else {
                        this.editHistory.s = res.data.memo_s
                        this.editHistory.o = res.data.memo_o
                        this.editHistory.suggestion = res.data.memo_p
                    }
                }
            })
        },
        // 儲存建議紀錄
        updateMrNote() {
            this.suggestionFeeback = true
            const payload = {
                type: this.doctortype,
                qid: this.queue_id,
                memop: this.memo_p,
            }
            updateMRNoteData(payload).then((res) => {
                this.suggestionFeeback = false
            })
        },
        // 更新醫療人員客觀診斷評估
        updateSubjective() {
            this.memoSFeeback = true
            const payload = { mr_id: this.mr.mr_id, memo_s: this.memo_s }
            updateSubjective(payload).then((res) => {
                this.memoSFeeback = false
            })
        },
        // 更新醫療人員客觀診斷評估
        updateObject() {
            this.memoOFeeback = true
            const payload = { mr_id: this.mr.mr_id, memo_o: this.memo_o }
            updateObject(payload).then((res) => {
                this.memoOFeeback = false
            })
        },
        // 新增病歷ICD
        insertICD() {
            this.ICDFeeback = true
            this.resultICD.push({
                label: this.ICD[0].ename,
                code: this.ICD[0].code,
                notes: '',
            })
            const payload = {
                mr_id: this.mr.mr_id,
                disease_code: this.ICD[0].code,
                notes: '',
            }
            upsertICD(payload).then((res) => {
                this.ICDFeeback = false
            })
            this.ICD = []
            this.$nextTick(() => {
                this.$refs.icd.$refs.search.focus()
            })
        },
        // 修改病歷ICD
        updateICD(event) {
            this.ICDFeeback = true
            let payload = {
                mr_id: this.mr.mr_id,
                disease_code: event.data.code,
                notes: event.data.notes,
            }
            upsertICD(payload).then((res) => {
                this.ICDFeeback = false
            })
        },
        // 移除病歷ICD
        deleteICD(event) {
            if (event.colDef.field != 'del') return
            this.ICDFeeback = true
            let payload = {
                mr_id: this.mr.mr_id,
                disease_code: event.data.code,
            }
            this.resultICD = _.remove(this.resultICD, function (n) {
                return n.code != event.data.code
            })
            deleteICD(payload).then((res) => {
                this.ICDFeeback = false
            })
        },
        // 新增病歷藥品
        insertMedicine() {
            this.medicineFeeback = true
            let payload = {
                id: this.resultMedicine.length + 1,
                mr_id: this.mr.mr_id,
                med_info_id: this.medicine[0].code,
            }
            upsertMedicine(payload).then((res) => {
                this.medicineFeeback = false
            })
            this.resultMedicine.push({
                id: this.resultMedicine.length + 1,
                med_info_id: this.medicine[0].code,
                medical_en_name: this.medicine[0].medical_en_name,
                unit: '',
                dose: 0,
                med_freq_id: '',
                med_route_id: '',
                days: 0,
                total_dose: 0,
                daily_dose: 0,
                notes: '',
            })
            this.medicine = []
            this.$nextTick(() => {
                this.$refs.medicine.$refs.search.focus()
            })
        },
        // 修改病歷藥品
        updateMedicine(event) {
            this.medicineFeeback = true
            let payload = {
                id: event.data.id,
                mr_id: this.mr.mr_id,
                type: event.colDef.field,
                value: event.newValue,
            }
            upsertMedicine(payload).then((res) => {
                this.medicineFeeback = false
            })
        },
        // 移除病歷藥品
        deleteMedicine(event) {
            if (event.colDef.field != 'del') return
            this.medicineFeeback = true
            let payload = {
                id: event.data.id,
                mr_id: this.mr.mr_id,
            }
            deleteMedicine(payload).then((res) => {
                this.medicineFeeback = false
            })
            this.resultMedicine = _.remove(this.resultMedicine, function (n) {
                return n.id != event.data.id
            })
            this.resultMedicine.forEach((v, i) => {
                v.id = i + 1
            })
        },
        // 新增處方集藥品
        insertMedicinePackage() {
            this.resultMedicinePackage.push({
                med_info_id: this.medicine[0].code,
                medical_en_name: this.medicine[0].medical_en_name,
                unit: '',
                dose: 0,
                med_freq_id: '',
                med_route_id: '',
                days: 0,
                total_dose: 0,
                daily_dose: 0,
                notes: '',
            })
            this.medicine = []
            this.$nextTick(() => {
                this.$refs.medicine.$refs.search.focus()
            })
        },
        // 移除處方集藥品
        deleteMedicinePackage(event) {
            if (event.colDef.field != 'del') return
            this.resultMedicinePackage = _.remove(this.resultMedicinePackage, function (n) {
                return n.med_info_id != event.data.med_info_id
            })
        },
        // 取處方集
        fetchMedicinePackage() {
            fetchMedicinePackage(this.partner_id).then((res) => {
                if (res.data.status == 'OK') {
                    this.medicinePackage = _.cloneDeep(res.data.data)
                }
            })
        },
        // 開啟儲存處方集視窗
        openSaveMedicinePackagePopup() {
            this.resultMedicinePackage = _.cloneDeep(this.resultMedicine)
            this.gridApiMedicinePackage.setDomLayout('autoHeight')
            document.querySelector('#medicinePackageGrid').style.height = ''
            this.saveMedicinePackagePopup = true
        },
        // 初始化表格
        onGridReadyMedicinePackage(params) {
            this.gridApiMedicinePackage = params.api
        },
        // 儲存處方集
        async saveMedicinePackage() {
            const payload = {
                partner_id: this.partner_id,
                package_code: this.packageCode,
                package_name: this.packageName,
                items: this.resultMedicinePackage,
            }
            await saveMedicinePackage(payload).then((res) => {
                if (res.data.status == 'OK') {
                    const notiPayload = { title: this.$t('message.save_success') }
                    this.$common.notify(notiPayload)
                } else {
                    const notiPayload = { title: this.$t('message.save_error') }
                    this.$common.notify(notiPayload)
                }
            })
            this.packageCode = ''
            this.packageName = ''
            // 取處方集
            await this.fetchMedicinePackage()
            this.saveMedicinePackagePopup = false
        },
        // 匯入處方集
        importMedicine() {
            this.medicineFeeback = true
            var items = _.find(this.medicinePackage, {
                package_code: this.medicinePackageCode[0].code,
            }).items
            items.forEach((v) => {
                v.id = this.resultMedicine.length + 1
                this.resultMedicine.push({
                    id: v.id,
                    med_info_id: v.med_info_id,
                    medical_en_name: v.medical_en_name,
                    unit: v.unit,
                    dose: v.dose,
                    med_freq_id: v.med_freq_id,
                    med_route_id: v.med_route_id,
                    days: v.days,
                    total_dose: v.total_dose,
                    daily_dose: v.daily_dose,
                    notes: v.notes,
                })
            })
            const payload = {
                mr_id: this.mr.mr_id,
                items: items,
            }
            upsertMedicine(payload).then((res) => {
                if (res.data.status) {
                    this.$nextTick(() => {
                        this.medicineFeeback = false
                    }, 1000)
                }
            })
            this.medicinePackageCode = []
        },
        // 取藥品的用法＆途徑
        fetchMedFreqRoute() {
            fetchMedFreqRoute().then((res) => {
                if (res.data.status == 'OK') {
                    this.freqArr = res.data.freq
                    this.routeArr = res.data.route
                }
            })
        },
        // 更新連續處方籤
        updateContinuousPrescription() {
            this.medicineFeeback = true
            let payload = {
                mr_id: this.mr.mr_id,
                type: this.continuousPrescription ? 2 : 1,
            }
            updateContinuousPrescription(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.medicineFeeback = false
                }
            })
        },
        // 取合作藥局
        fetchPharmacyList() {
            fetchPharmacyList(this.partner_id).then((res) => {
                this.pharmacyList = res.data.data
            })
        },
        // 搜尋ICD10
        get_icd10(word) {
            this.ICD10DropdownShouldOpen = word ? true : false
            if (!word) return false
            this.optionICD = []
            get_icd10(word).then((res) => {
                res.data.items.forEach((v) => {
                    let duplicate = false
                    this.resultICD.forEach((v2) => {
                        if (v.code == v2.code) {
                            duplicate = true
                        }
                    })
                    if (duplicate) return false
                    this.optionICD.push({
                        label: '(' + v.code + ')' + v.ename,
                        code: v.code,
                        cname: v.cname,
                        ename: v.ename,
                        notes: '',
                    })
                })
            })
        },
        // 搜尋藥品資訊
        get_medicine_info(word) {
            this.medicineDropdownShouldOpen = word ? true : false
            if (!word) return false
            this.optionMedicine = []
            get_medicine_info(word).then((res) => {
                res.data.items.forEach((v) => {
                    this.optionMedicine.push({
                        label: '(' + v.code_id + ')' + v.medical_en_name,
                        code: v.code_id,
                        medical_en_name: v.medical_en_name,
                    })
                })
            })
        },
        // 搜尋藥品集
        get_medicine_package_info(word) {
            this.medicinePackageDropdownShouldOpen = word ? true : false
            if (!word) return false
            this.optionMedicinePackage = []
            this.medicinePackage.forEach((v) => {
                if (v.package_code.match(word)) {
                    this.optionMedicinePackage.push({
                        label: '(' + v.package_code + ')' + v.package_name,
                        code: v.package_code,
                    })
                }
            })
        },
        ICD10DropdownShouldOpenFunc() {
            return this.ICD10DropdownShouldOpen
        },
        medicineDropdownShouldOpenFunc() {
            return this.medicineDropdownShouldOpen
        },
        medicinePackageDropdownShouldOpenFunc() {
            return this.medicinePackageDropdownShouldOpen
        },
        // 搜尋商品
        getCartProduct(word) {
            let payload = {
                query: word,
                partner_id: this.partner_id,
                cart_token: localStorage.getItem('cart_token'),
            }
            if (!word) return false
            let duplicate = false
            this.optionProduct = []
            searchcartproduct(payload).then((res) => {
                if (res.data.data == '') return false
                if (res.data.status == 'ERROR TOKEN') {
                    localStorage.removeItem('cart_token')
                }
                res.data.data.forEach((v) => {
                    this.reportrecomm.forEach((v2) => {
                        if (v.name == v2.name) {
                            duplicate = true
                        }
                    })
                    if (duplicate) {
                        duplicate = false
                        return false
                    }
                    this.optionProduct.push({
                        label: '(' + v.product_id + ')' + v.name,
                        id: v.product_id,
                        name: v.name,
                        price: v.price,
                        quantity: v.quantity,
                        manufacturer: v.manufacturer,
                        description: v.description,
                        url: v.url,
                        image: v.image,
                    })
                })
            })
        },
        // 放入推薦商品表格
        insertProduct() {
            let duplicate = _.find(this.reportrecomm, { product_id: this.PRODUCT[0].id }) ? true : false
            if (!duplicate) {
                this.reportrecomm.push({
                    name: this.PRODUCT[0].name,
                    product_id: this.PRODUCT[0].id,
                    price: this.PRODUCT[0].price,
                    quantity: 1,
                    manufacturer: this.PRODUCT[0].manufacturer,
                    description: this.PRODUCT[0].description,
                    url: this.PRODUCT[0].url,
                    image: this.PRODUCT[0].image,
                })
            }
            this.PRODUCT = []
        },
        // 從表格刪除商品
        deleteProduct(event = '') {
            if (this.recomm.length != 0) {
                const notiPayload = { title: this.$t('emr.apptwsssssx.cart_errors') }
                this.$common.notify(notiPayload)
                return
            }
            this.reportrecomm = _.remove(this.reportrecomm, function (n) {
                return n.name != event.data.name
            })
        },
        // 新增常用商品
        setProduct(id) {
            let payload = {
                product_id: id,
                partner_id: localStorage.getItem('partnerid'),
            }
            setProduct(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        const notiPayload = { title: this.$t('message.create_success') }
                        this.$common.notify(notiPayload)
                    }
                })
                .finally(() => {
                    this.getDoctorProducts()
                })
        },
        // 新增推薦商品
        insertRecomm(to = '/apptmgmt/1') {
            const payload = {
                spid: this.$route.params.pid,
                queue_id: this.$route.params.qid,
                data: [],
                cart_token: localStorage.getItem('cart_token'),
                partner_id: localStorage.getItem('partnerid'),
            }
            let number = 0
            this.reportrecomm.forEach((v) => {
                if (v.quantity > 0) {
                    payload.data[number] = {
                        product_id: v.product_id,
                        quantity: v.quantity,
                        snapshot: {
                            name: v.name,
                            price: v.price,
                        },
                    }
                    number++
                }
            })

            // 選取推薦產品數量才呼叫,無法用disable判斷資料
            if (payload.data[0]) {
                insertRecommend(payload).then((res) => {
                    const notiPayload = res.data.status == 'CHANGE OK' ? { title: this.$t('message.update_success') } : { title: this.$t('message.create_success') }
                    this.$common.notify(notiPayload)
                    this.insertRecommendPopup = false
                    this.$router.push(to)
                })
            } else {
                const notiPayload = { color: 'danger', title: this.$t('emr.appt.cart_error') }
                this.$common.notify(notiPayload)
            }
        },
        // 開啟商城預覽圖片視窗
        openCartImagePopup(name, price, image, url) {
            this.cartProductName = name
            this.cartProductPrice = price
            this.cartProductImage = image
            this.cartProductUrl = url
            this.cartImagePopupActive = true
        },
        // 關閉商城預覽圖片視窗
        closeCartpopup() {
            this.cartImagePopupActive = false
            this.cartProductName = ''
            this.cartProductPrice = 0
            this.cartProductImage = ''
            this.cartProductUrl = ''
        },
        // 前往商城 手機版開新分頁還有問題
        goToCart() {
            if (this.device == 2) {
                window.open(this.cartProductUrl, '_system')
            } else {
                window.open(this.cartProductUrl, '_blank')
            }
        },
        // 取推薦商品shop recommm
        getRecommend() {
            let payload = {
                spid: this.$route.params.pid,
                queue_id: this.$route.params.qid,
                cart_token: localStorage.getItem('cart_token'),
                partner_id: localStorage.getItem('partnerid'),
            }
            getRecommend(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.recomm = res.data.data
                    if (this.recomm) {
                        this.recomm.forEach((v) => {
                            this.reportrecomm.push({
                                name: v.name,
                                price: v.price,
                                quantity: v.quantity,
                                manufacturer: v.manufacturer,
                                description: v.description,
                                url: v.url,
                                image: v.image,
                            })
                        })
                    }
                }
            })
        },
        // 開啟寫入推薦商品視窗
        openInsertRecommPopup() {
            this.insertRecommendPopup = true
        },
        // 關閉寫入推薦商品視窗
        closeRecommPopup() {
            this.insertRecommendPopup = false
            this.recommendList = []
        },
        // 以存取商品開視窗
        handleRecommCellClick(event) {
            if (event.colDef.field == 'image') {
                this.openCartImagePopup(event.data.name, event.data.price, event.data.image, event.data.url)
            } else if (event.colDef.field == 'del') {
                this.deleteProduct(event)
            } else if (event.colDef.field == 'star') {
                this.checkIsStar(event.data.product_id) ? this.deleteDoctorProducts(event.data.product_id) : this.setProduct(event.data.product_id)
            } else {
                return
            }
        },
        // 取常用商品
        getDoctorProducts() {
            let payload = {
                partner_id: localStorage.getItem('partnerid'),
                cart_token: localStorage.getItem('cart_token'),
            }
            getDoctorProducts(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.doctorProducts = res.data.data
                    } else if (res.data.status == 'ERROR TOKEN') {
                        localStorage.removeItem('cart_token')
                        // this.getAllowShop()
                    }
                })
                .then(() => {
                    const temp = this.reportrecomm
                    this.reportrecomm = []
                    const _self = this
                    temp.forEach(function (v) {
                        _self.reportrecomm.push(v)
                    })
                })
        },
        // 開啟使用常用商品
        openUseProductPopup() {
            this.getDoctorProducts()
            this.useProductPopup = true
        },
        // 關閉使用常用商品
        closeUseProductPopup() {
            this.useProductPopup = false
        },
        // 套用常用商品
        useProduct() {
            const _self = this
            this.checkUseProduct.forEach(function (v) {
                let duplicate = _.find(_self.reportrecomm, { product_id: v.product_id }) ? true : false
                if (!duplicate) _self.reportrecomm.push(v)
            })
            this.checkUseProduct = []
            this.useProductPopup = false
        },
        // 刪除常用商品
        deleteDoctorProducts(id) {
            const payload = {
                product_id: id,
                partner_id: localStorage.getItem('partnerid'),
            }
            deleteProductFromDB(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        const notiPayload = { color: 'danger', title: this.$t('message.delete_success') }
                        this.$common.notify(notiPayload)
                    }
                })
                .finally(() => {
                    this.getDoctorProducts()
                })
        },
        // 是否為常用商品
        checkIsStar(id) {
            return _.find(this.doctorProducts, { product_id: id }) ? true : false
        },
        // 通話
        call(ring = 1, direction = 1) {
            console.log('queue', this.queue)
            const payload = { pid: this.queue.pid, ring: ring, direction: direction }
            if (this.queue) {
                payload.queue_id = this.queue.queue_id
                if (this.queue.cs_id > 0 && this.queue.proxy_call_did != this.did) {
                    // 會診 打給另一個醫師
                    payload.mid = this.queue.callback_doctor.mid
                    payload.is_doctor = 1
                } else if (this.queue.cs_id > 0 && this.queue.did != this.did) {
                    // 會診 打給另一個醫師
                    payload.mid = this.queue.doctor_info.mid
                    payload.is_doctor = 1
                } else if (this.queue.cs_id == 0 && this.queue.proxy_call_did > 0) {
                    // 代理掛號 打給掛號時指定的醫事人員
                    payload.mid = this.queue.callback_doctor.mid
                    payload.is_doctor = 1
                } else {
                    // 打給民眾
                    payload.mid = this.queue.patient_info.mid
                    payload.is_doctor = 0
                }
            } else {
                // 打給民眾
                payload.mid = this.selectedPatient.mid
                payload.is_doctor = 0
            }
            if (this.queue.room_id) payload.room_id = this.queue.room_id
            this.$store.dispatch('call_p2p', payload).then((res) => {
                if (res.data.status != 'OK') {
                    this.$store.dispatch('updateDoctorStatus', 1000)
                }
            })
        },
        // 開啟 邀請視窗
        showInvitePopup(qid, pmid) {
            const payload = {
                qid: qid,
                mid: pmid,
            }
            if (this.queue.room_id) payload.room_id = this.queue.room_id
            this.$bus.$emit('invite', payload)
        },
        // 更新 完診狀態
        updateFinishStatus(to = '/apptmgmt/1') {
            this.$store.dispatch('updateDoctorStatus', 1000)
            let payload = { qid: this.queue_id, finish: 1 }
            updateFinishStatus(payload).then((res) => {
                if (res.data.status === 'OK') {
                    const notiPayload = { title: this.$t('FinishTxt') }
                    this.$common.notify(notiPayload)
                    this.passFlag = true
                    this.doctorLeavePopup = false
                    this.$router.push(to)
                }
            })
        },
        // 離開 (回掛號管理)
        exit() {
            this.$router.push('/apptmgmt/1')
        },
        checkCTAConfig(key) {
            if (this.queue.remote_queue_id == 0) return true
            return this.remoteApptConfig[key].show
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('importHistory', (item) => {
                if (item.key == 'memo_o') {
                    const breakLine = _.endsWith(this.memo_o, '\n') || !this.memo_o ? '' : '\n'
                    this.memo_o = this.memo_o + breakLine + item.value
                }
                if (item.key == 'memo_p') {
                    const breakLine = _.endsWith(this.memo_p, '\n') || !this.memo_p ? '' : '\n'
                    this.memo_p = this.memo_p + breakLine + item.value
                }
                if (item.key == 'json_a') {
                    this.ICDFeeback = true
                    item.value.forEach((v) => {
                        this.resultICD.push(v)
                        const payload = {
                            mr_id: this.mr.mr_id,
                            disease_code: v.code,
                            notes: v.notes,
                        }
                        upsertICD(payload)
                    })
                    this.ICDFeeback = false
                }
                if (item.key == 'json_p') {
                    this.medicineFeeback = true
                    item.value.forEach((v) => {
                        v.id = this.resultMedicine.length + 1
                        this.resultMedicine.push(v)
                    })
                    const payload = {
                        mr_id: this.mr.mr_id,
                        items: this.resultMedicine,
                    }
                    upsertMedicine(payload)
                    this.medicineFeeback = false
                }
                const notiPayload = { title: this.$t('message.copy_success') }
                this.$common.notify(notiPayload)
            })
            this.$bus.$on('sendInvite', (data) => {
                console.log('on sendInvite: ', data)
                this.queue.room_d = data.room_id
                console.log('apptData: ', this.apptData)
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('importHistory')
        this.$bus.$off('sendInvite')
        this.patientNotesPopup = false
        this.saveMedicinePackagePopup = false
        this.useProductPopup = false
        this.cartImagePopupActive = false
        this.insertRecommendPopup = false
    },
}
</script>
